export interface Organization {
  id: number;
  name: string;
  role: OrganizationsRolesEnum;
  total_users: number;
  logo: string;
  default_meeting_tool_source: '' | 'calendar' | 'zoom';
}

export enum OrganizationsRolesEnum {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  ORGANIZER = 'ORGANIZER',
  MEMBER = 'MEMBER',
}

export interface OrganizationInvitations {
  id: number;
  organization: OrganizationInvitation;
  status: OrganizationInvitationStatus;
  invitor_name: string;
  invitee_status: OrganizationInviteeStatus;
  invitee_email: string;
  next_step: OrganizationInvitationNextStep;
}

export interface OrganizationInvitation {
  id: number;
  name: string;
}

export type OrganizationInvitationStatus =
  | 'PENDING'
  | 'PENDING_ACCEPTED'
  | 'ACCEPTED'
  | 'DECLINED'
  | 'REVOKED';

export enum OrganizationInviteeStatus {
  REGISTERED = 'REGISTERED',
  NOT_REGISTERED = 'NOT_REGISTERED',
}

export enum OrganizationInvitationNextStep {
  ACCEPT_DECLINE,
  SIGN_UP,
}

export interface IAddOrganizationsUser {
  role: string;
  email: string;
}

export interface IOrganizationCalendarSettings {
  id: number;
  organization_name: string;
  organization_logo: string;
  booking_calendar: number;
  availability_calendars: IOrganizationavAilabilityCalendars[];
  organization_id: number;
}

export interface IUpdateOrganizationCalendarSettings {
  booking_calendar: number;
  availability_calendars: IOrganizationavAilabilityCalendars[];
  update_default_meeting_tool_source: boolean;
}

export interface IOrganizationStripeSettings {
  id: number;
  name: string;
  stripe_account: string;
  stripe_account_email: string;
  stripe_account_owner_name: string;
  stripe_account_connected_by_myself: boolean;
}

export interface IUpdateOrganizationStripeSettings {
  stripe_account: number | null;
}

export interface IOrganizationZoomSettings {
  id: number;
  name: string;
  zoom_tool: number | null;
  zoom_tool_email: string;
  zoom_tool_owner_name: string;
}

export interface IUpdateOrganizationZoomSettings {
  zoom_tool: number | null;
}

export interface IUpdateDefaultMeetingTool {
  default_meeting_tool_source: 'calendar' | 'zoom' | '';
}

export interface IOrganizationavAilabilityCalendars {
  calendar: number;
  subcalendars: number[];
}

export interface IDemotionDetails {
  id: number;
  last_organizer_in_group: boolean;
  has_meeting_templates: boolean;
}
