import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { IconModule } from '@cf/shared/ui/icons';
import { ButtonDirective } from '../button/button.directive';

@Component({
  selector: 'cf-button-loading',
  imports: [CommonModule, IconModule],
  templateUrl: './button-loading.component.html',
  host: {
    class: 'tw:contents',
  },
})
export class ButtonLoadingComponent {
  #button = inject(ButtonDirective);

  loadingText = input<string>('Loading...');

  loading = this.#button.loading;
}
